<template>
  <div class="main-body">
    <!-- <div class="table-operator">
      <a-button type="primary" @click="goTo()">新的模板</a-button>
    </div> -->
    <a-table class="doc-table" rowKey="id" :dataSource="dataSource" :columns="columns" :pagination="Refpagination" @change="onLoadData">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <a-button type="primary" class="margin-right15" size="small" @click="template_test(record.template_id)">test
          </a-button>
          <a-button type="primary" v-if="record.wechat_is_open==0" danger size="small" @click="template_open(record)">开启</a-button>
          <a-button type="primary" v-if="record.wechat_is_open==1" size="small" @click="template_close(record)">关闭</a-button>
          <!-- <a-button type="primary" class="margin-right15" size="small" @click="edit(record.template_id)">编辑
          </a-button>
          <a-button type="primary" danger size="small" @click="delete(record.template_id)">删除</a-button> -->
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import * as Api from "@/views/client/wechatApi";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const formState = reactive({});
    const dataSource = ref();
    const Refpagination = ref({});
    const onLoadData = (pagination) => {
      if (typeof pagination !== "undefined") {
        formState.page = pagination.current;
      }
      Api.wechat_list_template(formState).then((res) => {
        Refpagination.value.current = res.data.template_list.current_page;
        Refpagination.value.pageSize = res.data.template_list.per_page;
        Refpagination.value.total = res.data.template_list.total;
        dataSource.value = res.data.template_list.data;
      });
    };

    const goTo = () => {
      router.push("/client/wechat/material_publish");
    };

    const edit = () => {
      router.push("/client/wechat/material_publish");
    };

    const template_test = (template_id) => {
      const dataForm = {};
      dataForm.template_id = template_id;
      Api.wechat_reply_test(dataForm).then((res) => {
        if (res.status == 200) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
    };

    const template_open = (record) => {
      const dataForm = {};
      dataForm.template_id = record.template_id;
      dataForm.wechat_is_open = 1;
      Api.wechat_reply_open(dataForm).then((res) => {
        if (res.status == 200) {
          message.success(res.message);
          record.wechat_is_open = 1;
        } else {
          message.error(res.message);
        }
      });
    };

    const template_close = (record) => {
      const dataForm = {};
      dataForm.template_id = record.template_id;
      dataForm.wechat_is_open = 0;
      Api.wechat_reply_open(dataForm).then((res) => {
        if (res.status == 200) {
          message.success(res.message);
          record.wechat_is_open = 0;
        } else {
          message.error(res.message);
        }
      });
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
    });

    return {
      template_test,
      template_open,
      template_close,
      goTo,
      edit,
      dataSource,
      Refpagination,
      onLoadData,
      columns: [
        {
          title: "id",
          dataIndex: "template_id",
          key: "id",
          width: "10%",
        },
        {
          title: "template_id",
          dataIndex: "wechat_template_id",
          key: "wechat_template_id",
          width: "10%",
        },
        {
          title: "关键词",
          dataIndex: "keywords",
          key: "keywords",
          width: "10%",
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "10%",
        },
      ],
    };
  },
});
</script>